import { defineStore } from "pinia";
import { directus } from "../services/directus";
import { readItems } from "@directus/sdk";
import { usePreferencesStore } from "./preferences";

export const useMenuStore = defineStore("navigation_menus", {
  state: () => ({
    main_menu: [],
    footer_menu: [],
    footer_bottom_navigation: [],
  }),
  getters: {
    getMenuItemByPath: (state) => {
      return (slug) => {
        const allItems = [];
        const menu_items = [].concat(
          state.main_menu,
          state.footer_menu,
          state.footer_bottom_navigation
        );
        menu_items.forEach((item) => {
          //add items to all items
          allItems.push(item);
          if (item.sub_items) {
            item.sub_items.forEach((sub_item) => {
              allItems.push(sub_item);
              if (sub_item.third_items) {
                sub_item.third_items.forEach((sub_sub_item) => {
                  allItems.push(sub_sub_item);
                });
              }
            });
          }
        });
        return allItems.find((item) => item.full_slug == slug);
      };
    },
    getSlugById: (state) => {
      return (id) => {
        const allItems = [];
        const menu_items = [].concat(
          state.main_menu,
          state.footer_menu,
          state.footer_bottom_navigation
        );
        menu_items.forEach((item) => {
          //add items to all items
          allItems.push(item);
          if (item.sub_items) {
            item.sub_items.forEach((sub_item) => {
              allItems.push(sub_item);
              if (sub_item.third_items) {
                sub_item.third_items.forEach((sub_sub_item) => {
                  allItems.push(sub_sub_item);
                });
              }
            });
          }
        });
        return allItems.find((item) => item.page?.key == id)?.full_slug || "#";
      };
    },
  },
  actions: {
    async updateNavigation(type) {
      const preferencesStore = usePreferencesStore();
      const language = preferencesStore.language;
      await directus
        .request(
          readItems("navigation_menus", {
            fields: ["*.*"],
            filter: { code: { _eq: type } },
          })
        )
        .then((resp) => {
          const menu_items = resp[0].translations.find(
            (item) => item.languages_id == language
          )?.menu_items;

          const pre_lang_attr = "en-US" === language ? "/en" : "";

          //SLUG GENERATION FIRST
          menu_items.forEach((item) => {
            if (item.slug) {
              item.full_slug = pre_lang_attr + "/" + item?.slug;
              //SECOND
              item.sub_items?.forEach((sub_item) => {
                sub_item.full_slug = item?.full_slug + "/" + sub_item?.slug;
                //THIRD
                sub_item.third_items?.forEach((sub_sub_item) => {
                  sub_sub_item.full_slug =
                    sub_item?.full_slug + "/" + sub_sub_item?.slug;
                });
              });
            } else {
              item.full_slug = "en-US" === language ? "/en" : "/";
            }
          });

          this[type] = menu_items;
        });
    },
    async updateAllNavigations() {
      return new Promise((resolve) => {
        const promises = [];
        promises.push(this.updateNavigation("main_menu"));
        promises.push(this.updateNavigation("footer_menu"));
        promises.push(this.updateNavigation("footer_bottom_navigation"));
        Promise.all(promises).then(() => {
          resolve();
        });
      });
    },
  },
});
