import {
  createRouter,
  createWebHistory,
  createMemoryHistory,
} from "vue-router";

import { useMenuStore, usePageStore, usePreferencesStore } from "../stores";
import { isLoading, mobileNavOpen } from "../services/helpers";
import { useHead } from "@vueuse/head";
const PageView = () => import("../views/PageView.vue");

const router = createRouter({
  history: import.meta.env.SSR
    ? createMemoryHistory(import.meta.env.BASE_URL)
    : createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "HOME",
      component: PageView,
    },
    {
      path: "/en",
      name: "ENGLISH HOME",
      component: PageView,
    },
    {
      path: "/en/404",
      name: "ENGLISH 404",
      component: () => import("../views/NotFoundView.vue"),
    },
    {
      path: "/en/:pageName",
      component: () => import("../views/PageView.vue"),
      children: [
        {
          //Childs
          path: ":subPageName",
          component: () => import("../views/PageView.vue"),
          children: [
            {
              //Childs of Childs
              path: ":subSubPageName",
              component: () => import("../views/PageView.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/:pageName",
      component: () => import("../views/PageView.vue"),
      children: [
        {
          //Childs
          path: ":subPageName",
          component: () => import("../views/PageView.vue"),
          children: [
            {
              //Childs of Childs
              path: ":subSubPageName",
              component: () => import("../views/PageView.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/",
      name: "SEARCH",
      component: () => import("../views/SearchView.vue"),
    },
    {
      path: "/en",
      name: "ENGLISH_SEARCH",
      component: () => import("../views/SearchView.vue"),
    },
    {
      path: "/404",
      name: "404",
      component: () => import("../views/NotFoundView.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  //show loader
  isLoading.value = true;
  //close mobile nav on routing
  mobileNavOpen.value = false;

  const menuStore = useMenuStore();

  const call = async () => {
    const preferenceStore = usePreferencesStore();
    const lang = preferenceStore.language;
    const url_is_english = to.fullPath.search("/en");

    if ("de-DE" === lang && url_is_english === 0) {
      // console.debug("switch language to english");
      useHead({
        htmlAttrs: { lang: "en-US" },
      });
      await preferenceStore.updateLanguage("en-US");
    }
    if ("en-US" === lang && url_is_english != 0) {
      // console.debug("switch language to german");
      useHead({
        htmlAttrs: { lang: "de-DE" },
      });
      await preferenceStore.updateLanguage("de-DE");
    }
    const navigation_element = menuStore.getMenuItemByPath(to.fullPath);

    if (
      typeof navigation_element == "undefined" &&
      to.name != "404" &&
      to.name != "ENGLISH 404" &&
      !to.query.s
    ) {
      url_is_english === 0
        ? next({ name: "ENGLISH 404" })
        : next({ name: "404" });
    } else {
      //try to find search param, if so, go to search page if not already there
      if (to.query.s && to.name != "SEARCH" && to.name != "ENGLISH_SEARCH") {
        url_is_english === 0
          ? next({ name: "ENGLISH_SEARCH", query: to.query })
          : next({ name: "SEARCH", query: to.query });
      } else {
        const pageStore = usePageStore();
        await pageStore.updatePage(navigation_element?.page?.key);
        next();
      }
    }
  };

  if (!menuStore.getMenuItemByPath(to.fullPath)) {
    menuStore.updateAllNavigations().then(() => {
      call();
    });
  } else {
    call();
  }
});

export default router;
