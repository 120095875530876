<template>
  <transition
    enter-active-class="animate__animated animate__fadeIn
    animate__faster"
    leave-active-class="animate__animated
    animate__fadeOut animate__faster"
    mode="out-in"
  >
    <search-bar v-if="showSearch" />
  </transition>
  <main-header />

  <router-view v-slot="{ Component, route }">
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
      mode="out-in"
    >
      <component :is="Component" :key="route.path" />
    </transition>
  </router-view>
</template>

<script setup>
import { onMounted, onBeforeMount } from "vue";
import { RouterView } from "vue-router";
import MainHeader from "./components/MainHeader.vue";
import SearchBar from "./components/SearchBar/SearchBar.vue";
import { showSearch } from "./services/helpers";
import { usePreferencesStore } from "./stores";
import { useHead } from "@vueuse/head";

import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const preferencesStore = usePreferencesStore();

const { locale } = useI18n({ useScope: "global" });
onMounted(async () => {
  //erst wenn Router fertig ist, kann Sprache gesetzt werden, sodass Cookiebot die richtige Sprache anzeigt.
  await router.isReady();
  preferencesStore.updateGivenLanguages();
  preferencesStore.updateButtonTypes();
});

onBeforeMount(() => {
  useHead({
    htmlAttrs: {
      lang: () => preferencesStore.language,
    },
    // script: [
    //   {
    //     src: "https://consent.cookiebot.com/uc.js",
    //     "data-cbid": "9d3a31c7-ad6e-4c91-ba56-4abe0a7dbb0c",
    //     "data-blockingmode": "auto",
    //     type: "text/javascript",
    //     "data-culture": locale.value,
    //   },
    // ],
    link: [
      {
        rel: "canonical",
        href: () => "https://sachsenkabel.de" + route.path,
      },
    ],
  });
});
</script>
